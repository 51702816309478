body {
  direction: rtl;
  text-align: right;
  unicode-bidi: embed;
}

ul {
  text-align: right;
  direction: rtl;
}
.site-header {
  .main-navigation {
    ul {
      li {
        .sub-menu-arrow {
          left: -0.75rem;
          right: auto;
        }
      }
    }
  }
}

// Home
.services-list {
  ul {
    li {
      &::before {
        margin-left: 0.8rem;
        margin-right: 0;
      }
    }
  }
}
.faqs {
  ul {
    li {
      > h3 {
        &:before,
        &:after {
          left: 1rem;
          right: auto;
        }
      }
      .text {
        padding: 1rem;
        display: none;
      }
    }
  }
}

// Footer
.site-footer {
  .widget {
    ul {
      li {
        &::before {
          margin-left: 0.8rem;
          margin-right: auto;
        }
      }
    }
  }
}
// Components
.blue-btn {
  padding: 1rem 1.5rem 1rem 3rem;
  background-position: left 0.5rem center;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E %3Cpath d='M6 12H18M6 12L11 7M6 12L11 17' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E %3C/g%3E%3C/svg%3E");
  background-position: left 0.5rem center;
}

// Forms
form {
  input,
  textarea {
    direction: rtl;
    text-align: right;
  }
}

// Contact Us
.main-content {
  .addresses {
    ul {
      li {
        svg {
          margin-left: 1rem;
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .site-header {
    .main-navigation {
      > ul {
        > li {
          &.menu-item-has-children {
            ul {
              right: 0;
              left: auto;
            }
          }
        }
      }
    }
  }
}
